app.controller('CommissionCtrl', [
    '$scope',
    '$uibModal',
    'Notif',
    'moment',
    'ContractService',
    'Site',
    function ($scope, $uibModal, Notif, moment, ContractService, Site) {
        $scope.yearOffset = {
            min: -1,
            max: 1
        };

        //legend + color constants
        $scope.LEGEND_IN_PROGRESS = "Berceau en cours"
        $scope.LEGEND_ADDED = "Berceau ajouté"
        $scope.COLOR_IN_PROGRESSS = "#2c3e50"
        $scope.COLOR_ADDED = "#00b894"


        $scope.cradles = [];
        $scope.cradleRows = [];
        $scope.years = [];
        $scope.quarters = [];
        $scope.commission = null;
        $scope.contracts = [];
        $scope.site = Site;
        $scope.initialCradle = 0; // berceau commandé réel

        var initQuarters = function () {
            var cv_mode = _.get($scope.site, [
                'options',
                'cv_mode'
            ], 'scholar');
            $scope.quarters = [];
            var qs = [
                "sept/oct/nov",
                "dec/janv/fev",
                "mars/avr/mai",
                "juin/juil/aout"
            ];
            if (cv_mode === 'civil') qs = [
                "janv/fev/mars",
                "avr/mai/juin",
                "juil/aout/sept",
                "oct/nov/dec"
            ]
            var range = $scope.yearOffset.max - $scope.yearOffset.min;
            for (var i = 0; i <= range; i++) $scope.quarters = _.concat($scope.quarters, qs);
        };

        var initYearsRange = function () {
            $scope.years = [];
            var year = moment().year();
            var cur = year + $scope.yearOffset.min;
            var max = year + $scope.yearOffset.max;
            while (cur <= max) $scope.years.push(cur++);
        };

        var getCommission = function (cb) {
            return ContractService.getCommission(function (commission) {
                var err = null;
                if (commission) $scope.commission = commission; else err = "Aucune commission trouvée";
                return cb(err);
            }, cb);
        };

        var getContracts = function (cb) {
            var range = [];
            var len = $scope.years.length;
            if (len) {
                range.push($scope.years[0]);
                range.push($scope.years[len - 1]);
            }
            return ContractService.listByYears({
                years: range,
                commission: $scope.commission._id
            }, function (contracts) {
                var toDelete = [];
                _.each(contracts, function (c) {
                    if (toDelete.indexOf(c._id) !== -1) return;
                    c.num -= 1;
                    if (!c.child || !c.creche || !c.creche._id) return;
                    var ces = _.get(c, 'date.effective.start');
                    if (ces) ces = ces.getTime();
                    var cee = _.get(c, 'date.effective.end');
                    if (cee) cee = cee.getTime();
                    if (cee && ces && ces >= cee) toDelete.push(c._id);
                    var others = [];
                    if (c.child) others = _.filter(contracts, function (oc) {
                        return oc._id != c._id && oc.child && oc.child == c.child && oc.creche && oc.creche._id && oc.creche._id == c.creche._id
                    });
                    if (others.length < 1) return;
                    _.forEach(others, function (oc, i) {
                        toDelete.push(oc._id);
                        var oces = _.get(oc, 'date.effective.start');
                        if (oces && oces.getTime() < ces) _.set(c, 'date.effective.start', oces)
                        var ocee = _.get(oc, 'date.effective.end');
                        if (ocee && ocee.getTime() > cee) _.set(c, 'date.effective.end', ocee)
                    })
                });
                _.remove(contracts, function (c) {
                    return toDelete.indexOf(c._id) !== -1;
                });
                //contracts = _.sortBy(contracts, function(o) { return o.created});
                contracts.sort(comp).reverse();
                $scope.initialCradle = $scope.commission.num;
                $scope.contracts = contracts;
                return cb();
            }, cb);
        };

        $scope.getEvent = function (cradle, quarter) {
            if (_.isUndefined(cradle)) return '';
            var year = $scope.years[Math.floor(quarter / 4)];
            return year;
        };

        var getQuarter = function (dt) {
            var cv_mode = _.get($scope.site, [
                'options',
                'cv_mode'
            ], 'scholar');
            var month = dt.month;
            var scholarYear = dt.year;
            var q;
            if (cv_mode === 'civil') {
                if (month <= 2) q = 0; else if (month <= 5) q = 1; else if (month <= 8) q = 2; else if (month <= 11) {
                    q = 3;
                    if (month === 11) scholarYear += 1;
                }
            } else if (cv_mode === 'scholar') {
                if (month >= 2 && month <= 4) {
                    q = 2;
                } else if (month >= 5 && month <= 7) {
                    q = 3;
                } else if (month >= 8 && month <= 10) {
                    scholarYear += 1;
                    q = 0;
                } else if (month === 11) {
                    scholarYear += 1;
                    q = 1;
                } else {
                    q = 1;
                }
            }
            return q + 4 * (scholarYear - $scope.years[1]);
        };

        var makeCradleCells = function (contract, index) {
            var eff = contract.date.effective;

            if (!eff.start || !eff.end) return;

            var start = {
                year: eff.start.getFullYear(),
                month: eff.start.getMonth(),
                cell: -1,
                overflow: false
            };
            if (start.year < $scope.years[0] || (start.year == $scope.years[0] && start.month < 8)) {
                start.year = $scope.years[0];
                start.cell = 0;
                start.overflow = true;
            } else {
                start.cell = getQuarter(start);
            }

            if (!eff.start || !eff.end) return;

            var end = {
                year: eff.end.getFullYear(),
                month: eff.end.getMonth(),
                cell: -1,
                overflow: false
            };
            if (end.year > $scope.years[$scope.years.length - 1] + 1 || (end.year == $scope.years[$scope.years.length - 1] + 1 && end.month > 8)) {
                end.year = $scope.years[$scope.years.length - 1];
                end.cell = $scope.cradleRows[0].length - 1;
                end.overflow = true;
            } else {
                end.cell = getQuarter(end);
            }
            var row = $scope.cradleRows[contract.num];
            if (!row) return;
            for (var i = start.cell; i <= end.cell && i < $scope.quarters.length; i++) {
                if (!row[i]) row[i] = {
                    contracts: [],
                    start: false,
                    end: false
                };
                if (i === start.cell && !start.overflow) row[i].start = true;
                if (i === end.cell && !end.overflow) row[i].end = true;
                row[i].contracts.push(index);
            }
        };

        var makeCradles = function (cb) {
            var contracts = $scope.contracts;
            var maxNum = $scope.commission.num;

            $scope.cradleRows = Array(maxNum);
            for (var i = 0; i < maxNum; i++) $scope.cradleRows[i] = Array($scope.quarters.length);
            _.each(contracts, function (contract) {
                var index = $scope.cradles.push({
                    name: ContractService.getParentName(contract, 0) || ContractService.getParentName(contract, 1),
                    childName: ContractService.getChildFirstname(contract),
                    creche: _.get(contract.creche, 'name', '').toUpperCase(),
                    start: _.get(contract, 'date.effective.start'),
                    end: _.get(contract, 'date.effective.end')
                }) - 1;
                return makeCradleCells(contract, index);
            });
            return cb();
        };

        var initCradles = function () {
            $scope.cradles = [];
            $scope.cradleRows = [];
            return async.waterfall([
                getCommission,
                getContracts,
                //getCompany,
                makeCradles,
            ], function (err) {
                if (err) return Notif.error(err);
                return null;
            });
        };

        var init = function () {
            initYearsRange();
            initQuarters();
            initCradles();
        };
        init();

        $scope.changeDatesRange = function (offset) {
            $scope.yearOffset.min += offset;
            $scope.yearOffset.max += offset;
            init();
        };


        $scope.openDetailModal = function (contractIndexes) {
            var contracts = _.map(contractIndexes, function (i) {
                return $scope.cradles[i];
            });
            if (contracts.length > 0) {
                var modalInstance = $uibModal.open({
                    templateUrl: "views/detailModal.html",
                    controller: function ($scope, $uibModalInstance, Contracts) {
                        $scope.contracts = Contracts;
                        $scope.cancel = function () {
                            $uibModalInstance.dismiss('cancel');
                        };
                    },
                    size: 'lg',
                    resolve: {
                        Contracts: function () {
                            return contracts;
                        }
                    }
                });

                modalInstance.result.then(function (comment) {
                    // Return actions
                });
            }
        };


        //sort array with property created
        function comp(a, b) {
            return new Date(a.created).getTime() - new Date(b.created).getTime();
        }

    }
]);
