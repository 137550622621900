app.controller('CalendarCtrl', ['$scope', '$compile', '$timeout', '$uibModal', 'uiCalendarConfig', 'EventService', function($scope, $compile, $timeout, $uibModal, uiCalendarConfig, EventService) {
  var date = new Date();
  var d = date.getDate();
  var m = date.getMonth();
  var y = date.getFullYear();

  $scope.eventSources = {};

  /* event source that contains custom events on the scope */
  $scope.events = [];

  $scope.loadEvents = function() {
    EventService.getEvents().success(function(data) {
      data.forEach(function(event) {
        event.start = moment(event.start, 'YYYY-MM-DD HH-mm').toDate();

        if (event.end) event.end = moment(event.end, 'YYYY-MM-DD HH-mm').toDate();

        event.stick = true;
        $scope.events.push(event);
      });
    });
  };

  $scope.openEventModal = function (event) {
    if(event) {
      var modalInstance = $uibModal.open({
        templateUrl: "views/eventModal.html",
        controller: function($scope, $uibModalInstance, Event) {
          $scope.event = Event;
          $scope.start = new Date(Event.start);
          $scope.end = new Date(Event.end);
          $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
          };
        },
        size: 'lg',
        resolve: {
          Event: function () {
            return event;
          }
        }
      });

      modalInstance.result.then(function () {
      });
    }
  };


  /* config object */
  $scope.uiConfig = {
    calendar:{
      height: 450,
      editable: false,
      header:{
        left: 'title',
        center: '',
        right: 'Today prev,next'
      },
      eventClick: $scope.openEventModal
    }
  };

  $scope.uiConfig.calendar.monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
  $scope.uiConfig.calendar.dayNames = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
  $scope.uiConfig.calendar.dayNamesShort = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];


  $scope.loadEvents();
  $scope.eventSources = [$scope.events];
}]);
