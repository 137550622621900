app.service('ContractService', ['$resource', function ($resource) {
  var resource = $resource('/api/contract/:_id', {_id: '@_id'}, {
    getCommission: {method: 'GET', isArray: false, url: '/api/commission'},
    listByYears: {method: 'GET', isArray: true,  url: '/api/commission/years' }
  });

  var formatDate = function (date) {
    var dates = [
      'planned.start', 'planned.end', 'real.start', 'real.end',
      'cancelled', 'terminated', 'bdc', 'signed',
      'effective.start', 'effective.end', 'priceAnticipated',
      'free.start', 'free.end'
    ];
    _.each(dates, function (d) {
      var dt = _.get(date, d);
      if (dt) _.set(date, d, new Date(dt));
    });
  };

  this.getChild = function (contract) {
    var children = _.get(contract, 'family.children', []);
    return _.find(children, {_id: contract.child});
  };
  
  this.getChildFirstname = function (contract) {
    return _.capitalize(_.get(this.getChild(contract), 'firstname', 'Inconnu'));
  };

  this.getParentName = function (contract, i) {
    var p = _.get(contract, 'family.parents.' + i);
    var name = [];
    if (p) {
      var firstname = _.capitalize(p.firstname);
      var lastname = _.toUpper(p.lastname);
      if (firstname) name.push(firstname);
      if (lastname) name.push(lastname);
    }
    return name.join(' ');
  }

  this.listByYears = function (query, successCb, errorCb) {
    return resource.listByYears(query, function (contracts) {
      _.each(contracts, function (c) {
        formatDate(c.date);
      });
      return successCb(contracts);
    }, errorCb);
  };

  this.getCommission = function (successCb, errorCb) {
    return resource.getCommission({}, successCb, errorCb);
  }
}]);
