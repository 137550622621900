app.controller('FicheFamilyCtrl', ['$scope', 'FamilyService', 'Family', 'Comments', 'Notif', function ($scope, FamilyService,  Family, Comments, Notif) {
    $scope.family = Family;
    $scope.comments = Comments;

    $scope.flagsEditMap = FamilyService.flagEdit;
    $scope.flagsMap = FamilyService.flagsMap;

    // $scope.canUpgrade = function() {
    //   var flag = FamilyService.getFlagFromStatus($scope.family.status);
    //   return FamilyService.flagEdit[flag];
    // }
    
    $scope.canUpgradeTo = function(flag) {
      var can = false;
      var statuses = $scope.flagsMap[flag] || [];
      var fflag = FamilyService.getFlagFromStatus($scope.family.status);
      for(var i=0; i<statuses.length; i++) {
      var fflag = FamilyService.getFlagFromStatus($scope.family.status);
        if($scope.flagsEditMap[fflag] && $scope.flagsEditMap[fflag].indexOf(statuses[i]) > -1) {
          can = true;
          break;
        }
      }
      return can;
    }

    $scope.upgradeStatus = function(flag) {
      var status = FamilyService.flagUpgradeTo[flag];
      if(!status) {
        Notif.error("Une erreur est survenue...");
        return;
      }
      $scope.family.newStatus = status;
        FamilyService.upgradeFamilyStatus($scope.family).then(function(res) {
            $scope.family = res.data;
            $scope.canUpgrade = false;
            Notif.success("Mise à jour effectuée avec succès !");
        }, function() {
          Notif.error("Une erreur est survenue...");
        })
    }

    $scope.getChildRythm = FamilyService.getChildRythm;

    $scope.getFamilyName = function() {
      var names = [];
      $scope.family.parents.map(function(parent) { if(parent.lastname && parent.lastname !== "") names.push(parent.lastname.toUpperCase())});
      return names.join(' / ');
    }

    $scope.getFamilySalary = function() {
        return  $scope.family.parents.map(function(parent) { return parseInt(parent.earning)}).reduce(function(a,b) {return a+b}, 0)
    }

    $scope.getFamilyFlag = function() {
      return FamilyService.getFamilyFlag($scope.family, true);
    }

}]);
