app.service('Notif', ['ngNotify', '_', function (ngNotify, _) {
  this.error = function (err) {
    if (err) {
      console.log(err);
      if (err.data) {
        if (err.data.errors) {
          var msg = [];
          _.each(Object.keys(err.data.errors), function (key) {
            msg.push(err.data.errors[key].message);
          });
          ngNotify.set(msg.join(' '), 'error');
        } else if (err.data.message){
          ngNotify.set(err.data.message, 'error');
        } else {
          ngNotify.set(err, 'error');
        }
      } else if (err.statusText){
        ngNotify.set(err.statusText, 'error');
      } else {
        ngNotify.set(err, 'error');
      }
    }
  };

  this.success = function (msg) {
    ngNotify.set(msg, 'success');
  };

  this.set = ngNotify.set;
}]);
