app.controller('HeaderCtrl', ['$scope', '$state', 'ngNotify', 'AuthService', 'DomainService', 'Contacts', function($scope, $state, ngNotify, AuthService, DomainService, Contacts) {
  $scope.hasContacts = Contacts.length > 0;
  $scope.subdomain = DomainService.getSubdomain();

  $scope.logout = function() {
    AuthService.logout()
      .success(function() {
        ngNotify.set("Vous avez bien été déconnecté", 'success');
        $state.go('auth');
      });
  };
}]);
