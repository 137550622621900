app.controller('ScoringCtrl', ['$scope', '$filter', '$anchorScroll', 'ScoringService', 'RegistrationCount', 'Site', function($scope, $filter, $anchorScroll, ScoringService, RegistrationCount, Site) {

  $scope.rules = _.get(Site, 'form.rules', []);
  $scope.attributions = _.get(Site, 'form.attributions', []);
  $scope.arbitrations = _.get(Site, 'form.arbitrations', [{label: "Date d’arrivée au sein de l’entreprise", rule: "+ ancien prioritaire"}]);
  $scope.registrations = [];
  $scope.pagination = {
    limit : 50,
    currentPage : 1,
    total : RegistrationCount
  };

  $scope.site = Site;

  $scope.getRegistrations = function() {
    ScoringService.getRegistrationList(($scope.pagination.currentPage-1)*$scope.pagination.limit,$scope.pagination.limit).then(
      function(response) {
        $scope.registrations = response.data;
      });
  };

  $scope.paginate = function () {
    $anchorScroll();
    $scope.getRegistrations();
  };
  $scope.getRegistrations();

  $scope.getMainParent = function(registration) {
    var mainParent = _.find(registration.family.parents, {'registrant': true});
    if(mainParent) return mainParent;
    if(registration.family.parents.length > 0) return registration.family.parents[0];
    return {lastname: "Aucun parent principal"};
  };

  $scope.getAddressStrings = function(registration) {
    var parent = $scope.getMainParent(registration);
    var array = [];
    if(!parent.address) return array;
    if(parent.address.street) array.push(parent.address.street);
    if(parent.address.zipCode) array.push(parent.address.zipCode);
    if(parent.address.city) array.push(parent.address.city);
    return array;
  };
}]);
