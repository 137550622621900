app.controller('HomepageCtrl', ['$scope', 'DomainService', 'AuthService', 'Site', function($scope, DomainService, AuthService, Site) {
  $scope.subdomain = null;
  $scope.user = null;
  $scope.site = Site;

  $scope.getSubdomain = function() {
    $scope.subdomain = DomainService.getSubdomain();
  };

  $scope.getUser = function() {
    $scope.user = AuthService.getAuthenticatedUser().user;
  };

  $scope.init = function() {
    $scope.getSubdomain();
    $scope.getUser();
  };

  $scope.init();
}]);
