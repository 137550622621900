app.controller('FamilyListCtrl', ['$scope', '$state', '$stateParams', '$filter', '$anchorScroll', 'ngNotify', 'FamilyFilterService', 'FamilyService', 'Flags', 'CanEdit', '$window', 'Site', 'HasChildView', 'IsArchive',
    function($scope, $state, $stateParams, $filter, $anchorScroll, ngNotify, FamilyFilterService, FamilyService, Flags, CanEdit, $window, Site, HasChildView, IsArchive) {

  $scope.flags = Flags || FamilyService.flags;

  $scope.strictModeText = "Si le mode strict est coché, seuls les résultats correspondants exactement aux jours séléctionnés apparaîtront.";
  $scope.canEdit = CanEdit;
  $scope.familyCounts = [];
  $scope.type = null;
  $scope.site = Site;
  $scope.hasChildView = HasChildView;
  $scope.loading = false;
  $scope.fullFamiliesLists = {};
  $scope.familiesLists = {};
  $scope.flags.forEach(function(flag) {
      $scope.fullFamiliesLists[flag] = [];
      $scope.familiesLists[flag] = [];
  });
  $scope.days = FamilyFilterService.getDays();
  $scope.yearsRange = FamilyFilterService.getYearsRange(IsArchive);
    $scope.popup1 = {
        opened: false
    };
    $scope.dateOptions = {
        startingDay: 1,
        datepickerMode: "month",
        minMode: "month",
    };
  $scope.structures = [];

  $scope.filters = FamilyFilterService.initFilters($stateParams);

  var getInitFamilyObjects = function() {
    FamilyService.getFamilyList(HasChildView, IsArchive).then(function(response) {
      $scope.loading = false;
      var families = response.data;
      families.forEach(function(family) {
        if(family.meta && family.meta.creche && $scope.structures.indexOf(family.meta.creche) === -1) {
            $scope.structures.push(family.meta.creche)
        }
        var flag = FamilyService.getFamilyFlag(family, $scope.hasChildView);
        if(flag) $scope.fullFamiliesLists[flag].push(family);
      });
        for(var flag in $scope.fullFamiliesLists) {
            $scope.familiesLists[flag] = $scope.fullFamiliesLists[flag]
        }
        if(FamilyFilterService.hasFilterFromUrl($stateParams)) $scope.applyFilters();
      selectedCurrentType();
    });
  };

  $scope.getChildRythm = FamilyService.getChildRythm;

  $scope.applyFilters = function() {
      FamilyFilterService.applyFilters($scope.fullFamiliesLists, $scope.familiesLists, $scope.filters);
      $scope.setFamilyFlag($scope.type);
      updateState();
  }

  var updateState = function() {
      var birthyear =  $scope.filters.birthyear;
      var startdate = $scope.filters.startdate ? FamilyFilterService.dateToString($scope.filters.startdate) : null;
      var rythms = $scope.filters.rythms.join(',');
      var structs = $scope.filters.structures.join(',');
      var strict = $scope.filters.strict ? 1 : null;
      $state.transitionTo($state.current, {birthyear: birthyear, startdate:startdate, strict:strict,rythms:rythms,structs:structs}, { notify: false });
  }

  //go through the flags until one of the lists is not empty or there is no more flags;
  var selectedCurrentType = function() {
    var currentFlag = null;
    for(var i = 0; i < $scope.flags.length; i++) {
      currentFlag = $scope.flags[i];
      if($scope.familiesLists[currentFlag].length > 0) break;
    }
    $scope.setFamilyFlag(currentFlag);
  };

  // var getFamilyFlag = function(family) {
  //   if(!$scope.hasChildView) return family.flag;
  //
  //     //todo: save the rules somewhere else?
  //   switch(family.status) {
  //       case "57922c3191033dbcd444c8e1":
  //         return 'Nouvelles inscriptions';
  //       case '578d47031abaec3e8ac50c98':
  //       case "578d47031abaec3e8ac50ca1":
  //       case "5828a4cce51ae57725a9abd6":
  //       case "578d47031abaec3e8ac50ca5":
  //       case "578d47031abaec3e8ac50c9f":
  //       case "5828a4cce51ae57725a9abd7":
  //         return 'Abandon Familles';
  //       case "586fd7f450b7a9f6081344e4":
  //       case '578d47031abaec3e8ac50c99':
  //       case "578d47031abaec3e8ac50c9a":
  //       case "578d47031abaec3e8ac50c9d":
  //       case "58c7b411ca61ddc2fbde2093":
  //       case "578d47031abaec3e8ac50c9e":
  //       case "58c7b411ca61ddc2fbde2094":
  //       case "578d47031abaec3e8ac50ca8":
  //       case "578d47031abaec3e8ac50c9b":
  //       case "58c7b411ca61ddc2fbde2095":
  //         return 'Liste attente entrée en direct';
  //       case "58875ff79db991a04d58bd35":
  //         return 'Contacts en direct';
  //       case "578d47031abaec3e8ac50ca0":
  //       case "578d47031abaec3e8ac50ca9":
  //         return 'Contrats entreprise';
  //       default:
  //         return 'En cours de traitement';
  //   }
  // }

  $scope.hasContracts = function() {
    return $scope.type === 'attributaire';
  };

  $scope.setFamilyFlag = function (flag) {
    $scope.families = $scope.familiesLists[flag];
    $scope.type = flag;
  }

  $scope.getMainParent = function(family) {
    var mainParent = _.find(family.parents, {'registrant': true});
    if(mainParent) return mainParent;
    if(family.parents.length > 0) return family.parents[0];
    return {firstname: "Aucun parent principal"};
  };

    $scope.getParentNames = function(family) {
      var names = [];
      family.parents.map(function(parent) { if(parent.lastname && parent.lastname !== "") names.push(parent.lastname.toUpperCase())});
      return names.join(' / ');
    }

  var init = function () {
    $scope.loading = true;
    getInitFamilyObjects();
  };

  init();

  $scope.exportList = function(format) {
    $window.open('/api/family/export.' + format, '_blank');
  }
}]);
