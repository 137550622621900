app.service('FamilyService', ['$http', function ($http) {

  this.getFamilyList = function(childrenList, isArchive) {
    return $http.get('api/family', {
      params: {
        options: {"sort":"created","order":"DESC","populate":true},
          childrenList: childrenList,
          isArchive: isArchive,
      }
    });
  };

  this.getFamilyCount = function(flag) {
    return $http.get('api/family/count', {
      params: {
        params: {"flag": flag}
      }
    });
  };

  this.upgradeFamilyStatus = function(family) {
    return $http.put('api/family/'+family._id+'/upgrade', family);
  };

  this.getFamilyFlags = function() {
    return $http.get('api/family/flags');
  };

  this.getFamilyStatus = function() {
      return $http.get('api/family/status');
  };

  this.getFamily = function(familyId) {
      return $http.get('api/family/' + familyId);
  };
  this.getFamilyComments = function(familyId) {
      return $http.get('api/family/' + familyId + '/comments');
  };

  this.getChildRythm = function(child) {
      var shortDays = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];
      if(child.days && child.days.length) {
          return child.days.map(function(day) {return shortDays[day]}.bind(this)).join(', ');
      } else if(child.rythm) {
          return child.rythm + ' jour' + (child.rythm > 1 ? 's' : '');
      } else {
          return '-';
      }
  }

  var flags = {
    "Nouvelles inscriptions": ['57922c3191033dbcd444c8e1'],
    "En cours de traitement": [],
    "Sans Potentiel - Pas d’appel maplaceencrèche": ['578d47031abaec3e8ac50c9b'],
    "Liste attente entrée en direct": [
      '586fd7f450b7a9f6081344e4', '578d47031abaec3e8ac50c99', '578d47031abaec3e8ac50c9a',
      '578d47031abaec3e8ac50c9d', '58c7b411ca61ddc2fbde2093', '578d47031abaec3e8ac50c9e',
      '58c7b411ca61ddc2fbde2094', '578d47031abaec3e8ac50c9e', '58c7b411ca61ddc2fbde2094',
      '578d47031abaec3e8ac50ca8', '58c7b411ca61ddc2fbde2095'
    ],
    "Contrats en direct": ['58875ff79db991a04d58bd35'],
    "Contrats entreprise": ['578d47031abaec3e8ac50ca0', '578d47031abaec3e8ac50ca9'],
    "Abandons Familles": [
      '578d47031abaec3e8ac50c98', '578d47031abaec3e8ac50ca1', '5828a4cce51ae57725a9abd6',
      '578d47031abaec3e8ac50ca5', '578d47031abaec3e8ac50c9f', '5828a4cce51ae57725a9abd7'
    ]
  }

  var flagEdit = {
    "Sans Potentiel - Pas d’appel maplaceencrèche": ['586fd7f450b7a9f6081344e4', '58875ff79db991a04d58bd35', '578d47031abaec3e8ac50c98'],
    "Liste attente entrée en direct": ['58875ff79db991a04d58bd35', '578d47031abaec3e8ac50c98']
  }

  var flagUpgradeTo = {
    "Liste attente entrée en direct": '586fd7f450b7a9f6081344e4',
    "Contrats en direct": '58875ff79db991a04d58bd35',
    "Abandons Familles": '578d47031abaec3e8ac50c98'
  }

  this.flags = _.keys(flags);
  this.flagsMap = flags;
  this.flagEdit = flagEdit;
  this.flagUpgradeTo = flagUpgradeTo;

  var getFlagFromStatus = this.getFlagFromStatus = function(status) {
    var flag, defaultFlag;
    _.forEach(_.keys(flags), function(f) {
      if(!flag) {
        if(_.isEmpty(flags[f])) defaultFlag = f;
        if(flags[f].indexOf(status) > -1) flag = f;
      }
    })
    if(!flag) flag = defaultFlag;
    return flag;
  }

  var getFamilyFlag = this.getFamilyFlag = function(family, HasChildView) {
    if(!HasChildView) return family.flag;
    return getFlagFromStatus(family.status);
    
  }
}]);
