app.service('SiteService', ['$http', function($http) {
  this.getCurrentSite = function() {
    return $http.get('api/site/current');
  };

  this.getCurrentApplication = function() {
      return $http.get('api/app/current');
  };


  this.hasViewChild = null;
  this.getAppType = function() {
      return this.getCurrentApplication().then(function(res) {
        this.hasViewChild = res.data && res.data.type == "partner" ? 1 : 0 ;
        return this.hasViewChild
      }.bind(this))
  }
}]);
