app.controller('AuthCtrl', ['$scope', '$state', 'Notif', 'AuthService', function ($scope, $state, Notif, AuthService) {
  $scope.email = null;
  $scope.password = null;

  $scope.login = function() {
    AuthService.login($scope.email, $scope.password)
      .then(function() {
        Notif.success("Vous avez bien été connecté");
        $state.go('home');
      }, Notif.error);
  };
}]);
