app.controller('RootCtrl', ['$scope', '$state', 'SiteService', function ($scope, $state, SiteService) {
  var self = this;
  $scope.loaded = false;
  this.bodyClass = '';
  $scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
    if(!$scope.loaded) $scope.loaded = true;
    if (angular.isDefined(toState.data.bodyClassCallback)) {
        self.bodyClass = toState.data.bodyClassCallback(SiteService.hasViewChild);
    } else if (angular.isDefined(toState.data.bodyClass)) {
      self.bodyClass = toState.data.bodyClass;
    }
  });
}]);
