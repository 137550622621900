app.service('FamilyFilterService', [ function() {

    this.getDays = function() {
        return [
            {id: 1, label: 'Lu'},
            {id: 2, label: 'Ma'},
            {id: 3, label: 'Me'},
            {id: 4, label: 'Je'},
            {id: 5, label: 'Ve'},
            {id: 6, label: 'Sa'},
            {id: 0, label: 'Di'},
        ]
    };

    this.getYearsRange = function(isArchive) {
        var currentYear = (new Date()).getFullYear();
        var firstYear = currentYear - 8;
        var returnArray = [];
        if(!isArchive) {
            for(var i=4; i>=0; i--) {
                returnArray.push(currentYear - i);
            }
            returnArray.push(currentYear + 1);
        } else {
            for(var i=firstYear; i<currentYear-4; i++) {
                returnArray.push(i);
            }
        }

        return returnArray;
    }

    this.hasFilterFromUrl = function($stateParams) {
        return $stateParams.startdate || $stateParams.birthyear || $stateParams.strict || $stateParams.rythms || $stateParams.structs;
    }

    this.initFilters = function($stateParams) {
        if($stateParams.startdate) {
            var startDate = new Date();
            var year = $stateParams.startdate.slice(0,4);
            var month = $stateParams.startdate.slice(4,6);
            startDate.setFullYear(parseInt(year));
            startDate.setMonth(parseInt(month));
        }

        var filters = {
            rythms: $stateParams.rythms ? $stateParams.rythms.split(',').map(function(e) {return parseInt(e)}) : [],
            strict: !!parseInt($stateParams.strict) || false,
            birthyear: $stateParams.birthyear || null,
            startdate: startDate || null,
            structures: $stateParams.structs ? $stateParams.structs.split(',') : [],
        };
        return filters;
    }

    this.applyFilters = function(fullFamiliesLists, familiesLists, filters) {
        for(var flag in fullFamiliesLists) {
            familiesLists[flag] = fullFamiliesLists[flag].filter(function(element) {
                if(filters.birthyear) {
                    var birthyear = (new Date(element.child.birthdate)).getFullYear();
                    if(birthyear != filters.birthyear) return false
                }

                if(filters.startdate) {
                    var startDate = new Date(element.child.startdate)
                    var startYearMonth =  this.dateToString(startDate);
                    var filterYearMonth = this.dateToString(filters.startdate);
                    if(startYearMonth !== filterYearMonth) return false
                }

                if(filters.structures.length) {
                    var structure = element.meta ? element.meta.creche : null;
                    if(!structure || filters.structures.indexOf(structure) === -1) return false
                }

                if(filters.rythms.length) {
                    var rythms = element.child.days;
                    if(!rythms || !rythms.length) return false;
                    var subLength = rythms.filter(function(e) { return filters.rythms.indexOf(e) > -1 }).length;
                    if(!subLength && !filters.strict || filters.strict && (subLength !== rythms.length || filters.rythms.length > rythms.length )) {
                        return false;
                    }
                }

                return true;
            }.bind(this));
        }
    }

    this.dateToString = function(date) {
        return ""+date.getFullYear()+date.getMonth()
    }

}])