'use strict';

// Define Angular app
var app = angular.module('app', [
  'ngResource', 'ui.router', 'ncy-angular-breadcrumb', 'ui.bootstrap', 'ngNotify', 'ui.calendar', 'mgcrea.ngStrap.select'
]);

// Define constants
app.constant('_', window._);
app.constant('moment', window.moment);
app.constant('AUTH_EVENTS', {
  notAuthenticated: 'auth-not-authenticated',
  notAuthorized: 'auth-not-authorized'
});

app.config(['$compileProvider', function ($compileProvider) {
  $compileProvider.aHrefSanitizationWhitelist(/^\s*(tel|mailto):/);
}]);

// Define routes
app.config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
  $stateProvider.state('root', {
    abstract: true,
    resolve: {
      Site: function(SiteService) {
        return SiteService.getCurrentSite().then(function(response) {
          return response.data;
        });
      },
      HasChildView: function(SiteService) {
          return SiteService.getAppType().then(function(hasViewChild) {
              return hasViewChild
          })
      },
      Contacts: function(ContactService) {
          return ContactService.getContacts().then(function(res) {
              return res.data || [];
          });
      }
    },
    views: {
      '': { templateUrl: 'partials/layout.html' },
      'header@root': {
        templateUrl: 'partials/header.html',
        controller: 'HeaderCtrl'
      },
      'sidebar@root': {
        controller: 'SidebarCtrl',
        templateUrl: 'partials/sidebar.html'
      },
      'footer@root': { templateUrl: 'partials/footer.html' }
    },
    data: {
      bodyClass: 'skin-blue sidebar-mini'
    }
  }).state('home', {
    url: '/',
    parent: 'root',
    resolve: {
      canAccess: function(HasChildView, $q, $state, $timeout) {
        //redirect to the family list when in partner mode
        var deferred = $q.defer();
        if(HasChildView) {
            $timeout(function() {
                $state.go('family-list');
            })
            deferred.reject()
        } else {
            deferred.resolve()
        }
        return deferred.promise;
      }
    },
    controller: 'HomepageCtrl',
    templateUrl: 'partials/index.html',
    ncyBreadcrumb: { label: 'Accueil' }
  }).state('auth', {
    url: "/auth",
    resolve: {
        HasChildView: function(SiteService) {
            return SiteService.getAppType().then(function(hasViewChild) {
                return hasViewChild
            })
        }
    },
    controller: 'AuthCtrl',
    templateUrl: "views/auth.html",
    data: {
      bodyClassCallback: function(HasChildView) {
        return 'hold-transition login-page '+(HasChildView?'login-page-partner':'');
      }
    }
  // }).state('bebe-relais', {
  //   url: '/bebe-relais',
  //   parent: 'root',
  //   templateUrl: 'views/bebe-relais.html',
  //   ncyBreadcrumb: { label: 'Bébé relais' }
  }).state('calendar', {
    url: '/calendar',
    parent: 'root',
    controller: 'CalendarCtrl',
    templateUrl: 'views/calendar.html',
    ncyBreadcrumb: { label: 'Calendrier' }
  // }).state('class', {
  //   url: '/class',
  //   parent: 'root',
  //   templateUrl: 'views/class.html',
  //   ncyBreadcrumb: { label: 'Soutien Scolaire' }
  }).state('commission', {
      url: '/commission',
      controller: 'CommissionCtrl',
      parent: 'root',
      templateUrl: 'views/commissions.html',
      ncyBreadcrumb: {label: 'Crèche virtuelle'}
      //     .state('conference', {
      //   url: '/conference',
      //   parent: 'root',
      //   templateUrl: 'views/conference.html',
      //   ncyBreadcrumb: { label: 'Parentalité entreprise' }
      // })
  }).state('contact', {
    url: '/contacts',
    parent: 'root',
    controller: 'ContactCtrl',
    templateUrl: 'views/contacts.html',
    ncyBreadcrumb: { label: 'Contacts' }
  }).state('contract', {
    url: '/contract',
    parent: 'root',
    templateUrl: 'views/contract.html',
    ncyBreadcrumb: { label: 'Contrat' }
  }).state('family-list', {
    url: '/family-list?birthyear&startdate&rythms&strict&structs',
    parent: 'root',
    templateUrl: 'views/family-list.html',
    controller: 'FamilyListCtrl',
    ncyBreadcrumb: { label: '' },
    resolve: {
      Flags: function(FamilyService, HasChildView) {
        if(HasChildView) return null;
        return FamilyService.getFamilyFlags().then(function(response) {
          return response.data;
        });
      },
      IsArchive: function() {
          return 0;
      },
      CanEdit: function(AuthService) {
        return AuthService.isAuthorized(["ROUTES_FAMILY_EDIT"]);
      }
    }
  }).state('family-archives', {
      url: '/family-archives?birthyear&startdate&rythms&strict&structs',
      parent: 'root',
      templateUrl: 'views/family-list.html',
      controller: 'FamilyListCtrl',
      ncyBreadcrumb: { label: '' },
      resolve: {
          Flags: function(FamilyService, HasChildView) {
              if(HasChildView) return null;
              return FamilyService.getFamilyFlags().then(function(response) {
                  return response.data;
              });
          },
          IsArchive: function() {
            return 1;
          },
          CanEdit: function(AuthService) {
              return AuthService.isAuthorized(["ROUTES_FAMILY_EDIT"]);
          }
      }
  // }).state('invoice', {
  //   url: '/invoice',
  //   parent: 'root',
  //   templateUrl: 'views/invoice.html',
  //   ncyBreadcrumb: { label: 'Factures' }
  }).state('critere', {
    url: '/critere',
    parent: 'root',
    templateUrl: 'views/scoring.html',
    controller: 'ScoringCtrl',
    resolve: {
      RegistrationCount: function(ScoringService) {
        return ScoringService.getRegistrationCount().then(function (response) {
          return response.data.count;
        });
      }
    },
    ncyBreadcrumb: { label: 'Scoring' }
  }).state('family-view', {
    url: '/fiche/:familyId',
      parent: 'root',
      templateUrl: 'views/family-fiche.html',
      controller: 'FicheFamilyCtrl',
      resolve: {
        Family: function(FamilyService, $stateParams) {
          return FamilyService.getFamily($stateParams.familyId).then(function(response) {
            return response.data;
          })
        },
        Comments: function(FamilyService, $stateParams) {
            return FamilyService.getFamilyComments($stateParams.familyId).then(function(response) {
                return response.data;
            })
        }

      }
  // }).state('workshop', {
  //   url: '/workshop',
  //   parent: 'root',
  //   templateUrl: 'views/workshop.html',
  //   ncyBreadcrumb: { label: 'Ateliers' }
  }).state('contacts', {
    url: '/contacts',
    parent: 'root',
    templateUrl: 'views/contacts.html',
    ncyBreadcrumb: { label: 'Contacts'}
  });

  $urlRouterProvider.when("", "/");
  $urlRouterProvider.otherwise(function ($injector) {
    $authService = $injector.get('AuthService');
    if ($authService.isAuthenticated()) {
      return '/';
    } else {
      return 'auth';
    }
  });

}]).config(function ($httpProvider) {
  $httpProvider.interceptors.push(function ($rootScope, $q, AUTH_EVENTS) {
    return {
      responseError: function (response) {
        $rootScope.$broadcast({
          401: AUTH_EVENTS.notAuthenticated,
          403: AUTH_EVENTS.notAuthorized
        }[response.status], response);
        return $q.reject(response);
      }
    };
  });
}).run(function ($rootScope, $state, $uibModal, ngNotify, AuthService, AUTH_EVENTS) {
  // Auth behavior

  $rootScope.$on('$stateChangeStart', function (event, next, nextParams, fromState) {

    if ('data' in next && 'authorizedRights' in next.data) {
      var authorizedRights = next.data.authorizedRights;
      if (!AuthService.isAuthorized(authorizedRights)) {
        //console.log("Droits insuffisants");
        event.preventDefault();
        $state.go('home');
      }
    }

    if (next.name !== 'auth') {
      if (!AuthService.isAuthenticated()) {
        //console.log("Authentification nécessaire");
        event.preventDefault();
        $state.go('auth');
      }
    }
    //console.log('changement d\'état: '+fromState.name+' vers '+next.name);
  });

  $rootScope.$on(AUTH_EVENTS.notAuthorized, function (event) {
    //console.log("Droits insuffisants");
    event.preventDefault();
    AuthService.logout();
    $state.go('auth');
  });

  $rootScope.$on(AUTH_EVENTS.notAuthenticated, function (event) {
    //console.log("Authentification nécessaire");
    event.preventDefault();
    $state.go('auth');
  });

  //Ng Notify config
  ngNotify.config({
    theme: 'pure',
    position: 'top',
    duration: 3000,
    type: 'warn',
    sticky: false,
    button: true,
    html: false
  });
});
